@use "src/styles/theme" as *; $themeName: "motrio";
@use '$styles/mediaqueries';


.container {
  padding: 18px;
  margin-top: 80px;

  @include mediaqueries.upToMedium {
    margin-top: 20px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 390px;
  position: relative;
}

.GoogleMap {
  width: 100%;
  height: 390px;
}
